.mobile-breadcrumb-title{
    font-family: 'OpenSans-SemiBold';
    font-size: 18px;
    margin: -20px 0 7px 0;
    display: flex
}

.loading-canvas-front {
    background-color: white;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: 170px 0px;
    min-height: 615px
  }

/* @media only screen and (max-width:496px){
    .mobile-breadcrumb-title{
        display: flex;
    }
} */