.react-select-custom div div > span,
.react-select-custom-searchable div div > span {
  display: none;
}

.react-select-custom input:hover {
  background-color: dodgerblue;
}

.react-select-custom-searchable div div > svg {
  display: none;
}

.dv-row-chkBox {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.label_icon_star {
  color: "#0b808e";
  font-weight: 500;
  padding: 0px 0px 20px 0px;
}

.check-box-groups {
  padding: 0px;
}

.checkbox-container {
  display: block;
  position: relative;  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
  max-width: 555px;
}

.checkbox-label {
  font-size: 14px;
  color: #454545;
  text-align: left;
  line-height: 18px;
  font-weight: normal;
}

.checkbox-checkmark {
  position: absolute;
  left: 0;
  height: 22px;
  width: 22px;
  cursor: pointer;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-checkmark:hover {
  border: 1px solid rgba(0, 82, 156, 1)
}

input[type="checkbox"] {
  margin: 0px;
}

.react-select-wrapper {
  position: relative;
}

.react-select-loader {
  margin-top:-11px;
}

.multi-select-box-loader-container {
  display:flex;
}

.multi-select-container {
  flex-grow:1;
}