

div.footer-container-root {
  background-color: rgba(255, 255, 255, 1);
  color: rgb(153, 153, 153);
  padding-top: 30px;
  padding-bottom: 20px;
  margin-top: auto;
  border-top: 2px solid rgba(179, 179, 179, 1);
  /* display: flex !important;  */
  width: 100%;
}

footer.global-footer {
  max-width: 1440px;
  margin: auto;
}

footer.global-footer a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

footer.global-footer>h1 {
  margin: 0;
}

.footer-text-container {
  width: 100%;
}

.content-display {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-links {
  top: 1060px;
  left: 436px;
  /* height: 19px; */
  /* width: 88px; */
  font-size: 14px;
  color: rgba(0, 82, 156, 1);
  text-align: left;
  font-family: 'OpenSans';
}

.footer-content {
  margin-top: 25px;
  color: rgba(69, 69, 69, 1);
  font-size: 14px;
  font-family: 'OpenSans';
}

.entrustLogo {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  padding-left: 45px;
  cursor: pointer;
  position: relative;
  top: -30px;
}

.mobile_logo {
  display: none;
}

.divider {
  color: rgba(216, 216, 216, 1);
  padding-left: 30px;
  padding-right: 30px;
}

.contact-number {
  color: antiquewhite;
}

.contact-number p {
  margin: 0;
}

.header-information-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-media-details {
  margin: 10px 0;
  color: rgb(153, 153, 153);
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
}

p {
  margin: 5px 0;
}

i {
  cursor: pointer;
}

@media (max-width: 767px) {
  .divider {
    display: none;
  }
  .mobile_logo {
    display: flex;
    margin: -10px 0 0 0;
    float: right;
  }
  div.footer-container-root {
    padding-top: 25px;
  }
  .footer-content {
    margin-top: 0;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 34px;
  }
  .content-display {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .entrustLogo {
    padding-top: 7px;
    top: 0;
  }
}

@media (max-width: 480px) {
  .divider {
    display: none;
  }
  .mobile_logo {
    display: flex;
    margin: -10px 0 0 0;
    float: right;
  }
  div.footer-container-root {
    padding-top: 25px;
  }
  .footer-content {
    margin-top: 0;
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 34px;
  }
  .content-display {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .entrustLogo {
    padding-top: 7px;
    top: 0;
  }
}

@media only screen and (max-width: 991px) {
  /* To-Do
  [EPR-675] Responsive footer menu.
  https://sureify.atlassian.net/browse/AAA-658 */
  .footer-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 34px;
    }
    .divider {
      display: none;
    }
 
}

@media (max-width:1024px) and (min-width:801px) {
  .divider {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 481px) and (orientation: landscape) {
  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 34px;
  }
  .divider {
    display: none;
  }
}
