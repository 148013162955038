.rangeslider {
    text-align: center;
    max-width: 550px;
    background:rgba(247, 247, 247, 1) !important;
  }
  .rangeslider-horizontal {
    height: 10px;
  }
  .rangeslider-horizontal .rangeslider__fill {
    background-color: rgba(247, 247, 247, 1) !important;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 24px !important;
    height: 24px !important;
    border-radius: 25px !important;
    background-color: rgba(0, 82, 156, 1) !important;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    background-color: rgba(0, 82, 156, 1) !important;
    border: none;
    width: 0px !important;
    height: 0px;
  }
  .rangeslider .rangeslider__handle {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline-style: none;
  }
  .center {
    text-align: center;
  }
  .defaultValue {
    font-size: 30px;
    font-weight: 500;
    font-family: 'OpenSans';
    color: rgba(0, 82, 156, 1);
  }
  .rangeslider__labels .rangeslider__label-item {
    color: rgba(69, 69, 69, 1);
    font-size: 14px;
    font-family: 'OpenSans'
  }
  
  ul.rangeslider__labels li.rangeslider__label-item:first-child {
    left: 95% !important;
  }
  
  .slider-container {
    margin: 0px;
    padding: 0px 25px 0px 25px;
  }

  .padding-null{
    padding: 0px !important;
    max-width: 555px !important;
  }
  
  @media only screen and (max-width: 480px) {
    .slider-container {
      margin: 0px;
      padding: 0px 25px 0px 25px;
    }
  }