.info{
font-size: 18px;
margin: 2px 2px 2px 10px;
color: rgba(0, 51, 141, 1)
}
.__react_component_tooltip.type-dark{
    color: black;
    border: 1px solid rgb(194, 193, 192);
    background-color: white;
    box-shadow: 1px 1px #888888;
    max-width: 270px;
}
.tooltip-text{
    font-weight: normal;
    text-align: left;
}
.tooltip-text .html-value {
    font-weight: bold;
    margin-bottom: 10px;
}
.tooltip-link{
   float: left;
   color: rgba(0, 82, 156, 1);
   margin: 15px 0 0 0;
   font-weight: 600;
   cursor: pointer;
   font-style: normal
}

.tooltip-box{
    opacity: 1 !important;
    white-space: normal !important;
}

/* @media only screen and (min-width:1240px) and (max-width:1380px) {
    .tooltip-box{
        top: 20% !important;
    }
}
@media only screen and (min-width:481px) and (max-width:1239px) {
    .tooltip-box{
        top: 20% !important;
    }
} */

@media only screen and (min-width:320px) and (max-width:480px) {
    .__react_component_tooltip.type-dark{
        margin-left: 10%;
    }
    .__react_component_tooltip:before, .__react_component_tooltip:after {
        content: none
    }
}

.__react_component_tooltip {
    width: calc(100% - 20px);
    max-width: 345px;
}
