.container_r {
  display: block;
  position: relative;
  padding-left: 20px;
  margin: 12px 0;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100px;
  font-size: 15px;
}

.container_r input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container_r input:checked ~ .checkmark {
  background-color: #0b808e;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container_r input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container_r .checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.radio-label {
  font-weight: normal;
  font-size: 16px;
}
