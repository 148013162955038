.react-datepicker__input-container input {
    padding: 9px;
  }
    
  .date-picker-custom-wrapper > div {
    flex: 1;
  }
  
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container > input {
    cursor: pointer;
    width: 131px;
    height: 44px;    
  }

  .react-datepicker-wrapper{
    border:0px solid !important;    
  }
  
  .calendar-logo {
    border: 0.1px solid rgb(172, 170, 170); 
    border-right: 0px;
    padding: 15.5px 15px 14px 15px;
    height: 44px;
    background-color: rgba(247, 247, 247, 1)
    /* margin-right: 0px;    */
  }
  .calendar-error-message{
    color: rgba(237, 28, 47, 1);
    margin: 10px 0 0 0;
  }
  .react-datepicker__input-container > input {
    font-family: 'OpenSans';
    height: 44px;
    margin: 0px 15px 0px 0px;
    padding:0px 12px;
    background-color: white;
    border-radius: 0px;
    border:solid 1px rgba(153, 153, 153, 1)     
}
.date-picker-logo{
  display: flex;
}
.date-picker-container{
  margin: 0 0 20px 0;
}

@media only screen and (max-width: 480px) {
  .react-datepicker-wrapper{
    cursor: pointer;
    width:85%;
    height: 44px;    
  }
  .react-datepicker__input-container, .react-datepicker__input-container > input {
    width: 100%
  }
}