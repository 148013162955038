.quote-content-wrapper {
  margin-left: 0px; /* Don't revert back. Right Panel content gets messed up */
}

.quote-content-title-wrapper {
  margin-bottom: 20px;
}

.normalize-margin{
   padding:20px 0 40px 0;
   border-bottom: 1px solid rgba(215, 215, 215, 1);
   max-width: 555px;
} 

.term-content-title-wrapper {
max-width: 600px;
}
div.term-content-title-wrapper:after {
  content: "";
  // border-bottom:1px solid grey;
  max-width:555px;
  display:block;
  padding-bottom: 63px;
  // border-bottom: 1px solid rgba(215, 215, 215, 1);
}

.rangeslider__label-item {
width: 55px;
}

.yourquote-content-title-wrapper{
margin:40px 0 0 0;
display: flex;
flex-direction: row;
max-width: 550px;
}
.quote-value{
color: rgba(45, 45, 45, 1);
font-size: 36px;
margin:0 15px 40px 224px;
font-family: OpenSans-SemiBold;
line-height: 1.1;
display: flex;
margin-left: auto
}

.quote-reccurence{
color: rgba(69, 69, 69, 1);
font-size: 14px;
line-height: 3;
font-family: 'OpenSans';
padding-left: 20px;
}

.your-quote{
font-size: 24px;
font-family: 'OpenSans';
color: rgba(0, 82, 156, 1)
}
.quote-feild-description{
display: flex;
margin: 0px 0 0 0;
}
.premium-range-wrapper {
  position: relative;
  padding-top: 0;
  margin: 0 auto;
  border-bottom: none;
}
.estimate-tooltip-text{
  margin-top: 5px;
}

.nowrap{
  white-space: nowrap;
}


@media only screen and (max-width: 480px), screen and (max-width:801px) {

 .left-content-wrap {
  width: 100%;
  line-height: 18px;
  margin-bottom: 0px;
}
.yourquote-content-title-wrapper{
  margin: 0 0 0 0;
  display:block;
  flex-direction: row;
  padding: 30px 0 60px 0;
}
.your-quote {
  text-align: center;
}
.quote-value {
  margin: 0px;
  text-align: center; 
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 6px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.quote-value-container {
  margin: 0;
  // margin-left: auto;
  // margin-top: auto;
  // margin-bottom: auto;
}
.quote-reccurence{
  margin: 0;
  // margin-right: auto;
  // margin-top: auto;
  // margin-bottom: auto;
}  
.normalize-margin{
padding:37px 0 40px 0;
}
.premium-range-wrapper {
  position: relative;
  top: -15px;
}
}

.number-value {
  font-weight: bold;
  font-size: 25px;
  color: #454545;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  @media only screen and (min-width:320px) and (max-width: 800px) {
    font-size: 15px;
  }

}
.border-for-num {
  border:  solid;
  border-width: 2px;
  margin-left:15px;
  margin-right: 15px;
}
.background-color {
  background-color: gray;
  color: white;
  font-size: 12px;
  text-align: center;
}
.quotes-header-title {
  font-size: 16px;
  font-weight: bold;
  font-family: 'OpenSans';
  color: rgba(0, 82, 156, 1);
  margin: 0 0 5px 0;
  text-align: center;;
}
.quotes-num {
  font-size: 55px;
  font-family: 'OpenSans';
  color: rgba(0, 82, 156, 1);
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0 !important;
  font-weight: bold;
}
.set-margin{
  margin-top:30px;
}

.quote-tenure-rangeslider .premium-selector {
  font-weight: bold;
  font-size: 20px;
  height: auto;
  padding: 15px 30px;
  border: 2px solid #c0c0c0;
  outline: none;
}

.quote-tenure-rangeslider .premium-selector.premium-selected {
  background: #c3c3c3;
  color: #ffffff;
}

@media screen and (max-width: 767px){
  .quotes-num {
    font-size: 36px;
  }
  .quote-tenure-rangeslider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .premium-range-wrapper {
    padding-bottom: 0;
  }
  .border-for-num {
    margin: 5px 0;
  }
  .set-margin {
    text-align: center;
    margin: 0!important;
  }
  .number-value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
