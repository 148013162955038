.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #000;
  border-color: rgba(0, 122, 137, 1) transparent #bbbbbb transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
  animation: lds-dual-ring 1.2s linear infinite;
}

.questions-btn-container {
  display: flex;
  flex-flow: row-reverse;
  padding-top: 20px;
}

@media only screen and (max-width: 500px) {
  .questions-btn-container {
    flex-flow: column !important;
  }
}

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
