.btn-footer {
    color: #ffffff;
    background-color: rgba(237, 28, 47, 1);
    border-radius: 25px;
    padding: 10px;
    position: initial;
    min-width: 270px;
    height: 46px;
    text-align: center;
    cursor: pointer;
    margin: 10px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    border: none;
    text-transform: none;
    font-size: 18px;
    font-weight: 600;
    font-family: 'OpenSans-SemiBold'
  }
  
  .btn-footer:focus {
    outline: 0;
  }
  
  .btn-footer:active {
    background-color: rgba(237, 28, 47, 1);
  }
  
  .btn-footer:hover {
    background-color:rgba(237, 28, 47, 1);
  }
  
  .btnPillLink {
    color: rgba(0, 115, 208, 1);
    background: transparent;
    font-family: 'OpenSans-Semibold';
    border: 0;
    padding: 10px;
    font-weight: 600;
    font-size: 18px;
    margin-right: 60px !important;
  }

  @media only screen and (max-width: 480px) {
    .btnPill {
        width: 335px !important;
    }
    .btn-footer {
      width: 110% !important;
      margin: 0px;

    }
    .btnPillLink {
      margin-right: 0px !important;
    }
  }

  .btn-rectangle{
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    position: initial;
    min-width: 100px;
    height: 46px;
    text-align: center;
    cursor: pointer;
    margin: 10px;
  }