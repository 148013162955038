.campaign-feilds{
    margin: 1px 1px 29px 1px;
    padding: 1px;
}

.left-subtitle {
    margin: 0px;
    max-width: 460px;
}

.line-dist {
    line-height: 1.5;
}

.label-title{
    font-family: 'OpenSans-SemiBold';
    margin: 1px 1px 8px 0px;
}
.campaign-note{
    font-family:'OpenSans-SemiBoldItalic';
    margin:-10px 0 34px 0;
    max-width: 555px;
}
.campaign-add-top-border{
    border-top: 1px solid rgba(215, 215, 215, 1);
    max-width: 555px;
}

.label-area{
  display: flex;
  flex-direction: row
}

.payment-link{
  color: blue;
  cursor: pointer;
  margin: 0 0 0 10px;
  display: block;
  width: 100%;
}

.payment-link:hover{
  text-decoration: underline
}

@media only screen and (min-width:768px) and (max-width:1280px){
  .campaign-feilds{
    margin: 1px 1px 29px 1px;
    padding: 1px;
}

.left-subtitle {
    margin: 0px;
    max-width: 460px;
}

.line-dist {
    line-height: 1.5;
}

.label-title{
    font-family: 'OpenSans-SemiBold';
    margin: 1px 1px 8px 0px;
}
.campaign-note{
    font-family:'OpenSans-SemiBoldItalic';
    margin:-10px 0 34px 0;
    max-width: 555px;
}
.campaign-add-top-border{
    border-top: 1px solid rgba(215, 215, 215, 1);
    max-width: 555px;
}

.label-area{
  display: flex;
  flex-direction: row
}

.payment-link{
  color: blue;
  cursor: pointer;
  margin: 0 0 0 10px;
  display: block;
  width: 100%;
}

.payment-link:hover{
  text-decoration: underline
}
}
@media only screen and (min-width:320px) and (max-width:768px){
  .display_flex_center {
    display: flex;
    flex-wrap: wrap;
  }
  .campaign-content-wrapper {
    margin-left: 0px;
  }
  .left-title {
    margin-bottom: 30px;
  }
  .left-subtitle{
    margin-right: 0px;
  }
  .campaign-feilds {
    margin: 0 0 30px 0;
  }
  .campaign-note {
    margin:0 0 36px 0;
  }
}

@media only screen and (max-width:395px) {
  br{
    display: none;
  }
}



