.add-ico {
  padding-right: 5px;
}

.add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

p.add-btn-text {
  font-size: 14px !important;
  margin: 0 !important
}
.popup-card-question {
  width: 100%;
}
.list_container {
  padding-right: 10px;
}

.footer {
  border-top: 1px solid rgba(226, 226, 226, 1);
  margin-top: 30px;
}

.highlightTxt {
  color: rgba(0, 115, 208, 1);
}

.review_breadcrumb {
  height: 22px;
  font-size: 20px;
  color: #454545;
  text-align: left;
  font-weight: 700;
}

.list_question_group_primary {
  padding-top: 25px;
  padding-bottom: 22px;
  font-size: 18px;
  color: #454545;
  text-align: left;
  font-weight: bold;
  display: inline-block;
}

.list_question_group_other {
  font-size: 18px;
  color: #454545;
  text-align: left;
  font-weight: bold;
  display: inline-block;
}

.edit-button {
  float: right;
  font-weight: 600;
  color:rgba(0, 82, 156, 1);
  cursor: pointer;
}

.delete-button {
  float: right;
  font-weight: 600;
  color:rgba(0, 82, 156, 1);
  cursor: pointer;
}
.addNewButton {
  background-color: rgba(0, 82, 156, 1);
  margin: 31px 0 28px 0 !important
}

.addNewButton:active {
  background-color: rgba(0, 82, 156, 1);
}

.addNewButton:hover {
  background-color:rgba(0, 82, 156, 1);
}

.dotted-line {
  padding-bottom: 15px;
  border-top: 2px dashed #b3b3b3;
  height: 1px;
  color: transparent;
}

.solid-line-list {
  padding-top: 22px;
  padding-bottom: 15px;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
  color: transparent;
}

.display_flex_center {
  display: flex;
  flex-flow: column wrap;
}

.display_flex_center > div {
  font-size: 14px;
  color: #454545;
  text-align: left;
  line-height: 21px;
}

.list_question {
  width: 150px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 21px;
  color: rgba(118, 118, 118, 1);
}

.list_answer {
  flex-grow: 1;
  color: rgba(69, 69, 69, 1);
  text-align: left;
  line-height: 21px;
  font-size: 14px;
  text-transform: capitalize;
}

.review_edit {
  width: 5px;
  padding-right: 25px;
}

.display_flex_list {
  display: flex;
  flex-flow: column wrap;
}
.display_flex_item {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.action-buttons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.action-buttons .edit-button {
  margin-bottom: 10px;
}

.action-buttons .delete-button {
  z-index: 1;
}

.additional-label {
  font-weight: 400;
}

.display_flex_button {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
}

@media only screen and (max-width: 670px) {
  .display_flex_center {
    flex-wrap: wrap;
  }

  .list_question {
    width: 100% !important;
  }

  .list_answer {
    padding: 0px !important;
    font-weight: 600 !important;
  }
}

.error-message-bottom {
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: -10px;
  color: #ff0000;
}
