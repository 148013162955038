div.content-container > div.left-panel p {
  padding: 0px;
}

.left-text-font{
  font-size: 16px;
  height: 21px;
}

.hide-question-element {
  display: none;
}

.aaa-card-tooltip img {
  width: 200px;
}
.disable-questions {
  pointer-events: none;
  opacity: 0.5;
}
