.containerCheckbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin: 12px 0;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: normal;
}

.containerCheckbox p {
  padding-left: 13px;
  font-size: 14px;
  color: #454545;
  text-align: left;
  line-height: 18px;
}

/* Hide the browser's default checkbox */
.containerCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkCheckbox {
  position: absolute;
  top: 7px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border: 1px solid #209cad;
}

/* On mouse-over, add a grey background color */
.containerCheckbox:hover input ~ .checkmarkCheckbox {
  border: 1px solid #0b808e;
}

/* When the checkbox is checked, add a blue background */
.containerCheckbox input:checked ~ .checkmarkCheckbox {
  background-color: rgba(0, 82, 156, 1);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkCheckbox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheckbox input:checked ~ .checkmarkCheckbox:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheckbox .checkmarkCheckbox:after {
  left: 8px;
  top: 4px;
  width: 7px;
  height: 12px;
  border: 0px solid white;
  border-width: 0px 3px 3px 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pd-top-10 {
  padding-top: 10px;
}
.pd-top-0 {
  padding-top: 0px;
}
