div.single-selection-button-group {
  display: flex;
  flex-direction: row;
}

@media only screen and (min-width:320px) and (max-width:480px) {
  div.single-selection-button-group {
    justify-content: space-between;
  }
}

div.single-selection-btn:hover {
  background-color: #d7d7d7;
}

.single-selected-active {
  background-color: #00529c !important;
  color: #f2f2f2;
}

.reflexive-question-loader {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reflexive-question-loader .lds-ring {
  width: 30px;
  height: 30px;
  position: relative;
  top: 0px;
}

.reflexive-question-loader .lds-ring div {
  height: 30px;
  width: 30px;
  border: 3px solid #00529C;
  border-color: #00529C transparent transparent transparent;
}
.radio-toolbar input[type="radio"]:checked + label {
  background-color: rgba(0, 82, 156, 1);
  color:rgba(255, 255, 255, 1);
  -webkit-transition: all ease 0.8s;
-moz-transition: all ease 0.8s;
transition: all ease 0.8s;
}
.radio-toolbar {
  flex-basis: 100%;
}
.radio-toolbar label {
  flex: 1;
  flex-basis: fit-content;
  height: 46px;
  width: 175px;
  border: 1px solid rgba(226, 226, 226, 1);   
  text-align: center;
  font-size: 15px;
  font-family: 'OpenSans-SemiBold';
  line-height: 38px;
  cursor: pointer;
  background-color: #F7F7F7;
  color: #999999;
}
