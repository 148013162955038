.display_review_flex_center {
  display: flex;
  min-height: 0px;
  justify-content: space-between;
}

.display_review_flex_center > div {
  font-size: 14px;
  color: #454545;
  text-align: left;
  line-height: 21px;
}

.display_review_question {
  width: 50% !important;
  min-width: 50% !important;
}

.display_review_answer {
  margin-right: 10%;
}

.review-dotted-line-break {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  border-top: 2px dashed #b3b3b3;
  height: 1px;
  color: transparent;
  margin-right: 10%;
}

.review-solid-line {
  margin-top: 10px !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
  height: 1px;
  color: transparent;
}
