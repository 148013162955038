.error-left-wrapper {
  .error-sorry-message {
    color: #00529c;
    font-size: 30px;
  }
}

.error-right-wrapper {
  .error-message {
    margin: 15px 0;
    font-size: 14px;
    color: #454545;
  }
  .call-number-right {
    font-size: 14px;
    font-weight: normal;
  }
}