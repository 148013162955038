.html-paragraph{
    font-family: 'OpenSans';
    font-size: 14px;
    margin: 0 15px;
}

.html-title{
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: bold;
    margin: 0 10px;
}