.bread-crumbs-progressbar{
  max-width: 100%;
}

.progress{
  height: 6px !important;
}

.breadcrumbs-header{
  display: flex;
}
.breadcrumbs-header-comp{
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.breadcrumbs-title {
  color: rgba(0, 82, 156, 1);
  font-family: 'OpenSans-SemiBold';
  padding: 10px 20px;
  width: max-content;
  white-space: nowrap;
}

.breadcrumb-border {
  height: 6px;
  background: #d8d8d8;
}

.breadcrumb-border.border-active,
.breadcrumb-border.all-completed {
  background: #00529c;
}

.border-first {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.border-last {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.title-value{
  display: block;
}

@media only screen and (max-width:767px){
  .breadcrumbs-header{
    display: none;
  }

  .breadcrumbs-header-comp{
    width: 100%;
  }
  .title-value{
    display: none;
    width: 100%;
  }
  .breadcrumb-body{
    width:100%;
  }
}
