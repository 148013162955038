div.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  
  div.message-container h3 {
    font-size: 1.4em;
    margin-top: 20px;
    padding: 50px 200px;
  }

  .thankyou-message{
    line-height: 30px
  }  

.left-text-heading {
  font-size: 18px;
  color: #000000;
}
