body {
  font-family: Helvetica, sans-serif;
}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  height: 44px;
  width: 300px;
  outline: none;
  padding: 12px 0px 11px 10px;
  margin: 0px;
  box-shadow: none;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  margin-bottom: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
  border: 1px solid #2424ff;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 300px;
  border: 1px solid rgb(206, 206, 206);
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 0.1px solid rgb(206, 206, 206)
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.txt-transform {
  text-transform: capitalize;
}

.no-pointer {
  cursor: not-allowed;
}

