.fa-exclamation-triangle {
    color: rgba(237, 28, 47, 1);
    padding: 30px 10px 0px 30px
}

.warning-container {
width: 555px;
border: 1px solid rgba(237, 28, 47, 1);
border-left-width: 10px;
border-radius: 5px;
margin-bottom: 30px;
}

.warning-title {
font-family: 'OpenSans';
font-size: 24px;
color: rgba(237, 28, 47, 1);
text-align: left;
display: block;
margin: -50px 0 0 92px;
}


.warning-text {
padding: 10px 13px 0 0px;
font-family: 'OpenSans-SemiBoldItalic';
font-size: 14px;
color: rgba(69, 69, 69, 1);
text-align: left;
line-height: 18px;
margin: 0px 0 0 95px;
width:445px;
}

@media  only screen and (max-width:479px), screen and (max-width:1024px)
{
    .warning-container{
        width: 100%;
        display: block
    }
    .error-logo{
        display: block;
        text-align: center;
    }
    .warning-title{
        text-align: center;
        padding: 9px 60px 0 60px;
        margin:0px
    }
    .warning-text{
        padding: 13px 20px 31px 20px;
        text-align: center;
        width:100%;
        margin:0px
    }
    .fa-exclamation-triangle {
        color: rgba(237, 28, 47, 1);
        padding: 22px 10px 0px 30px
    }
}