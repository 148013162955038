.group-dotted-line-break {
    margin-top: 22px !important;
    margin-bottom: 15px;
    border-top: 2px dashed #b3b3b3;
    height: 1px;
    color: transparent;
}

.group-solid-line {
    margin-top: 32px !important;
    margin-bottom: 52px !important;
    border-bottom: 1px solid #b3b3b3;
    height: 1px;
    color: transparent;
}

.group_question_lvl_3 {
    font-size: 16px;
    font-weight: none;
    padding-bottom: 40px;
}
.padding-bottom-15{
    padding-bottom: 15px
}