.input-static-container{
    display: flex;
    flex-direction: column
}
.input-title{
    font-family: 'OpenSans-SemiBold';
    margin: 1px 1px 8px 0px
}
.input-height-div{
    display: flex;
    flex-direction: row
}
.radio-toolbar label:focus {
  border: 2px solid #2982FB !important;
  outline:none;
}
.button-error-message{
    color: rgba(237, 28, 47, 1);
    margin: 10px 0 0 0;
}
.radio-toolbar {
  margin: 10px;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  width: 44%;
  max-width: 175px;
  flex-basis: 100%;
}

.radio-toolbar:last-child {
  margin-right: 0px;
}
  
  .radio-toolbar input[type="radio"] {
    display:none;
    opacity: 0;
    width: 0;
  }
  
  .radio-toolbar label {
    height: 46px;
    width: 175px;
    border: 1px solid rgba(226, 226, 226, 1);   
    text-align: center;
    font-size: 10px;
    font-family: 'OpenSans-SemiBold';
    margin:0px 0px 0px 0px;
    line-height: 38px;
    cursor: pointer;
    background-color: #F7F7F7;
    color: #999999;
  }
  .radio-toolbar input[type="radio"]:checked + label {
    background-color: rgba(0, 82, 156, 1);
    color:rgba(255, 255, 255, 1);
    -webkit-transition: all ease 0.8s;
	-moz-transition: all ease 0.8s;
	transition: all ease 0.8s;
  }  

  @media only screen and (max-width: 480px) {
    @-moz-document url-prefix() {
        .radio-toolbar label{
          width:40% !important;
        } 
      }
  } 