@font-face{
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/Fonts/OpenSans-Regular.ttf)
}

@font-face{
  font-family:'OpenSans-SemiBold';
  font-style: normal;
  font-weight: 400;
  src:url(./assets/Fonts/OpenSans-SemiBold.ttf)
}

@font-face{
  font-family:'OpenSans-SemiBoldItalic';
  font-style: normal;
  font-weight: 400;
  src:url(./assets/Fonts/OpenSans-SemiBoldItalic.ttf)
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif,"OpenSans",'OpenSans-SemiBold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
