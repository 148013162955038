.custom-text-input {
  display: flex;
  flex-direction: column;
}

.custom-text-input > input {
  height: 44px;
  width: 360px;
  outline: none;
  padding: 12px 0px 11px 10px;
  margin: 0px;
  box-shadow: none;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  margin-bottom: 10px;
}

.custom-text-input > input:hover {
  border-color: 1px solid #0074FF;
}

.custom-text-input > input:focus {
  outline: none;
  border: 1px solid #0074FF;
}

.custom-text-input > input[type="text"]:disabled {
  background: #fff;
  opacity: 0.75;
  pointer-events: none;
}

.error > input[type="text"] {
  border: 1px solid #d13b3b;
}

.text-field-status {
  margin: 0;
  font-size: 12px;
  color: #0b808e;
  font-weight: 500;
  cursor: pointer;
}

.label_icon_star {
  color: "#0b808e";
  font-weight: 500;
}

.error_type_mismatch {
  font-size: 12;
  color: "lightGrey";
  margin-top: 0;
}

.disable-page{
  pointer-events: none;
  opacity: 1;
}

@media only screen and (min-width:320px) and (max-width:480px) {
  .custom-text-input > input {
    width : 100%; 
  }
}

.show-error-msg {
  font-size: 12px;
  color: #ff0000;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 375px;
}