.interstital-wrapper {
    min-height: 450px;
}
.interstatial-title-wrapper{
    font-size: 24px;
    color: rgba(0, 82, 156, 1);
    margin-bottom: 10px;
    margin-top: -5px;
}
.interstatial-ordered-list{
    margin: 0 0 30px 0px;
    max-width: 555px;
}

@media only screen and (min-width:767px) {
    .interstatial-covid19-message {
        margin-top: -75px;
    }
}

.interstatial-covid19-message {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 25px;
}
  
.interstatial-covid19-message > a {
    font-weight: bold;
    font-size: 20px;
    white-space: nowrap;
}
  ol li {
    padding: 0px 5px 0px 5px;
  }

  ol {
      margin-bottom: 30px
  }

  .interstatial-imp-items{
      margin: 1px 1px 1px 20px
  }

  .interstatial-conclusion{
      margin: 13px 5px 157px 0;
      max-width: 555px;
  }

  @media only screen and (max-width:480px){
    .interstatial-conclusion{
        margin: 13px 0 15px 0;
    }
}