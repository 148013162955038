div.header-container-root {
    width: 100%;
  }

.header-row-1{
  display: flex;
  flex-direction: row;
  width: 100%;
}  

.header-row-2{
  width: 100%;
  display: flex;
  flex-direction: row;
}
  
  div.header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    max-width: 1440px;
    margin: auto;
    flex-direction: column
    /* border-bottom: 1px solid rgba(153, 153, 153, 1);
    background-color: #ffffff; */
  }
  
 .button-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-start;
  }
  
  .company-logo {
   width: 70%;
  }
  
  div.header-container span {
    margin: 0px;
  }
  
  .call-label {
    height: 18px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(69, 69, 69, 1);
    line-height: 18px;
    width: max-content;
    white-space: nowrap;
  }
  
  .call-number {
    font-weight: bold;
    font-family: 'OpenSans-Semibold';
    font-size: 18px;
    color: rgba(0, 82, 156, 1);
    /* text-align: left; */
    line-height: 24px;
    padding: 0 2px;
  }
  div.header-container span.agent-button {
    border-radius: 22px;
    background-color: rgba(0, 122, 137, 1);
    color: #ffffff;
    cursor: pointer;
    float: right;
    font-size: 14px;
    padding: 10px 35px;
    text-transform: uppercase;
  }
  .btnPill {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 20px;
  }
  
  .hamberger-header {
    display: none;
  }
  
  .hamberger-header > i {
    color: rgba(0, 115, 208, 1);
    
  }

  .breadcrumbs{
    margin: 20px 0 0 0;
    width: 70%;
  }
  .hamberger-menu{
    display: none
  }

  .vertical-center {
    align-self:center;
  }
  
  @media only screen and (min-width: 767px) {
    .hamberger-footer{
      display: none;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .header-container {
      padding: 0px 0px 0px 0px !important;
    }
  
    .company-logo {
      padding: 14px 0px 16px 20px;
      width: 90%
    }

    .display-none {
      display: none !important;
    }

    .display-flex {
      display: flex !important;
    }
    
    .breadcrumbs{
      width: 120%;
      margin: 0
    }

    .logo-breadcrumbs-container{
      width: 100%
    }

    .hamberger-header {
      display: block;
      cursor: pointer;
    }

    .call-label{
      display: none;
    }

    .hamberger-menu{
      display: flex;
      flex-direction: column;
      color: rgba(0, 82, 156, 1);
      font-family: 'OpenSans';
      font-weight: bolder;
      text-align: center
    }

    .header-row-2{
      flex-direction: column
    }

    .menu-options{
      margin: 10px auto 15px auto !important;
      width: 90%; 
      overflow: hidden;
      text-overflow: ellipsis; 
      white-space: nowrap;
    }

    .incomplete-menu{
      color: rgba(179, 179, 179, 1) !important
    }

    .hamberger-footer{
      text-align: center;
      padding: 25px 0;
      border-top: 1px solid rgba(216, 216, 216, 1)
    }

    .close-style:before{
      content: "\X" !important;
      font-family: 'OpenSans';
      font-weight: bolder;
      font-style: normal;
    }

  }

  