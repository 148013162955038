.global-body-canvas-root {
  width: 100%;
  padding: 0px 0px 0px 8%;
  background-color: #f7f7f7;
}

.global-body-canvas {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 680px;
}

.global-header {
  border-bottom: 1px solid rgba(153, 153, 153, 1);
  background-color: #ffffff;
  min-height: 80px;
}

#root {
  display: flex;
  flex-direction: column;
  background-color: rgba(247, 247, 247, 1);
}

.canvas-for-content {
  display: flex;
  flex: 1;
  font-family: 'OpenSans';
}

.left-canvas-content-wrapper {
  border-right: 1px solid rgba(215, 215, 215, 1);
  flex: 1;
  display: flex;
  background-color: rgba(247, 247, 247, 1);
  max-width: 520px;
}

.left-canvas-content-wrapper, .right-canvas-content-wrapper, .canvas-content-wrapper {
  padding-top: 120px;
}

.right-canvas-content-wrapper {
  max-width: 770px;
  flex: 1;
  display: flex;
  background-color: white;
  font-size: 14px;
  position: relative;
}

.normalize-layout-left {
  padding: 0px 57px 0px 20px !important;
  max-width: 570px;
}

.normalize-layout-right {
  padding: 0px 20px 0px 57px !important;
}

.global-header-title {
  font-size: 24px;
  font-family: 'OpenSans';
  color: rgba(0, 82, 156, 1);
  margin: 0 0 5px 0;
}

.global-sub-header-title {
  font-size: 18px;
  font-family: 'OpenSans-SemiBold';
  color: rgba(0, 82, 156, 1);
  text-align: center;
}

.global-feild-content {
  line-height: 18px;
  font-family: 'OpenSans';
  font-size: 14px;
  color: rgba(69, 69, 69, 1);
  max-width: 555px;
}

.margin-bottom-2 {
  margin-bottom: 2px;
  padding-top: 14px;
}

.leftsubcontent {
  margin-bottom: 30px;
}

.margin-top-19 {
  margin-top: 19px
}

.global-button-margin {
  border-top: 1px solid rgba(215, 215, 215, 1)
}

.global-left-panel-title {
  font-family: 'OpenSans-SemiBold';
  font-size: 30px;
  color: rgba(0, 82, 156, 1);
  margin-bottom: 15px;
  line-height: 25px;
  max-width: 470px;
}

.global-left-panel-content {
  font-family: 'OpenSans';
  font-size: 14px;
  color: rgba(69, 69, 69, 1);
}

.global-left-panel-sub-title {
  margin-bottom: 21px;
  font-family: 'OpenSans-SemiBold';
  font-size: 18px;
  color: rgba(0, 82, 156, 1)
}

.global-left-panel-moreBtn {
  width: 175px;
  font-family: 'OpenSans-SemiBold';
  font-size: 18px;
  color: rgba(0, 82, 156, 1);
  padding-top: 20px;
  border-top: 1px solid rgba(215, 215, 215, 1);
}

.fa-angle-down {
  padding-left: 10px
}

.right-canvas-content, .content-wrapper {
  max-width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.global-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 555px;
  padding: 30px 0;
  flex: 1;
  flex-flow: inherit;
}

.global-button-wrapper>button {
  margin-right: 0px;
}

.padding-bottom-10 {
  padding: 0 0 10px 0
}

.global-label-text {
  font-size: 14px;
  color: rgba(69, 69, 69, 1);
  text-align: left;
  line-height: 21px;
  font-weight: bold;
}

.global-left-panel-page-title{
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0
}

@media all  and (-ms-high-contrast:none) {
  *::-ms-backdrop, .right-canvas-content-wrapper {
    height: unset;
    flex:auto
  }
  .right-canvas-content, .content-wrapper {
    display: block;
    flex: 1;
    flex-direction: column
  } 

}

@media only screen and (min-width:767px) and (max-width:860px)  and (-ms-high-contrast:none) {
  .left-canvas-content-wrapper {
    max-width: 300px !important;
  }
}

@media only screen and (min-width:1281px) {
  .normalize-layout-left{
    padding: 0px 57px 0px 20px 
  } 
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
  .normalize-layout-left{
    padding: 0px 57px 0px 20px 
  } 
}

@media only screen and (min-width:768px) and (max-width:1024px) {
  .global-body-canvas-root {
    padding: 0px 0px 0px 20px;
  }
  .normalize-layout-left{
    padding: 0px 57px 0px 20px 
  } 
  .global-left-panel-title {
    width: 100%;
    line-height: 35px;
  }
}

@media only screen and (min-width:481px) and (max-width:767px) {
  .canvas-for-content {
    flex-flow: column;
  }
  .left-canvas-content-wrapper, .right-canvas-content-wrapper, .canvas-content-wrapper {
    padding-top: 30px;
    border-right: none;
  }
  .left-canvas-content-wrapper {
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    width: 100%;
    flex: none
  }
  .global-body-canvas-root {
    padding: 0px;
  }
  .normalize-layout-left, .normalize-layout-right {
    padding: 0 0 0 20px !important;
    width: 95%;
  }
  .global-left-panel-title {
    width: 100%;
    line-height: 35px;
  }
  .line-break {
    display: block;
    margin: 18px 0 0 0;
  }
  .padding-bottom-10 {
    padding: 0px 0 0px 0;
  }
  .global-button-wrapper {
    padding: 21px 20px 30px 20px;
    display: flex;
    flex-direction: column-reverse;
  }
  .global-feild-content {
    line-height: 18px;
    margin-bottom: 11px;
  }
  .global-left-panel-moreBtn {
    display: none;
  }
  .margin-bottom-2 {
    margin-bottom: 0px;
    padding-top: 27px;
  }
  .global-left-panel-sub-title {
    margin-bottom: 34px;
  }
  .global-header-title {
    margin-bottom: 12px;
  }
  .margin-top-19 {
    margin-top: 27px;
  }
  .global-button-wrapper .btnPillLink {
    margin-right: 0 !important;
  }
}
@media only screen and (min-width:320px) and (max-width:480px) {
  .canvas-for-content {
    flex-flow: column;
  }
  .left-canvas-content-wrapper, .right-canvas-content-wrapper, .canvas-content-wrapper {
    padding-top: 30px;
    border-right: none;
  }
  .left-canvas-content-wrapper {
    border-bottom: 1px solid rgba(215, 215, 215, 1);
    width: 100%;
    flex: none;
  }
  .global-body-canvas-root {
    padding: 0px;
  }
  .normalize-layout-left, .normalize-layout-right {
    padding: 0 0 0 20px !important;
    width: 95%;
  }
  .global-left-panel-title {
    width: 100%;
    line-height: 35px;
  }
  .line-break {
    display: block;
    margin: 18px 0 0 0;
  }
  .padding-bottom-10 {
    padding: 0px 0 0px 0;
  }
  .global-button-wrapper {
    padding: 21px 20px 30px 20px;
    display: flex;
    flex-direction: column-reverse;
  }
  .global-feild-content {
    line-height: 18px;
    margin-bottom: 11px;
  }
  .global-left-panel-moreBtn {
    display: none;
  }
  .margin-bottom-2 {
    margin-bottom: 0px;
    padding-top: 27px;
  }
  .global-left-panel-sub-title {
    margin-bottom: 34px;
  }
  .global-header-title {
    margin-bottom: 12px;
  }
  .margin-top-19 {
    margin-top: 27px;
  }
  .global-button-wrapper .btnPillLink {
    margin-right: 0 !important;
  }
  .mobile-view{
    flex-direction: column
  }
}

.div-loading {
  opacity: 0.5;
  pointer-events: none;
}
.align-horizontal {
  display: flex;
}
.align-horizontal .html-title {
  padding-right: 10px;
}
p {
  margin-bottom: 1rem !important;
}

.thankyou-message .call-number {
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}
.disable-input {
  opacity: 0.5;
  pointer-events: none;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}

input[type=text] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-wrap {
  white-space: nowrap;
}