.input-static-container{
    display: flex;
    flex-direction: column
}
.input-title{
    font-family: 'OpenSans-SemiBold';
    margin: 1px 1px 8px 0px
}
.input-type-area{
    font-family: 'OpenSans';
    height: 44px;
    margin: 0px 15px 0px 0px;
    padding:0px 12px;
    background-color: white;
    border-radius: 0px;
    border:solid 1px rgba(153, 153, 153, 1) 
}
.input-field-div {
    display: flex;
    flex-direction: row
}
.input-field-label {
    margin:9px 15px 0 0;
}

input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;   
}

input[type=number] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;   
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
/* input[type=number] {
    -moz-appearance:textfield;
} */

.input-radio-label{
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
}

.input-radio-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

.input-radio-checkmark{
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    border: 1px rgba(153, 153, 153, 1) solid
}
.input-radio-label:hover input ~ .input-radio-checkmark {
    background-color: #eee;
}
.input-radio-label input:checked ~ .input-radio-checkmark {
    background-color: #2196F3;
}
.input-radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.input-radio-label input:checked ~ .input-radio-checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .input-radio-label .input-radio-checkmark:after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }
  .input-error-message{
      color: rgba(237, 28, 47, 1);
      margin: 10px 0 0 0;
  }

  @media only screen and (max-width: 480px) {
    .input-type-area {
        display: flex !important;
        width: 100% !important;
    }
    @-moz-document url-prefix() {
        .moz-input {
        width: 40% !important;
         }
      }
  }

  @media all and (-ms-high-contrast:none){
    *::-ms-backdrop, 
    .input-type-area::-ms-clear {display: none; width:0; height:0;}
    .input-type-area {
        display: flex !important;
        max-width: 100% !important;
    }
    }

    