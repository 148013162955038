.review-question{
    display: flex;
    flex: 10
}
.review-question-text{
    flex: 6;
    font-family: 'OpenSans-SemiBold';
    font-size: 14px;
}
.review-question-answer{
    font-family: 'OpenSans';
    font-size: 14px;
    margin:5px 30px;
    flex: 2;
    text-transform: capitalize;
}
.review-question-header{
    font-family: 'OpenSans-SemiBold';
    font-size: 18px;
    color: rgba(0, 82, 156, 1);
    margin: 5px 0;
    flex: 10
}
.review-header-area{
    padding: 70px 0 20px 0;
    margin: 40px 0 15px 0;
    border-top: 1px solid rgba(179, 179, 179, 1);
}
.review-sub-header-area{
    margin: 0 0 15px 0;
    display: flex;
    padding: 0 0 21px 0;
    background-image: linear-gradient(to right, #b3b3b3 40%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 15px 1px;
    background-repeat: repeat-x;
}
.review-question-margin{
    margin-top: 50px
}
.review-question-update{
    flex: 1;
    margin:5px 30px;
    font-family: 'OpenSans-SemiBold';
    color:rgba(0, 82, 156, 1);
    
}
.update-text{
    cursor: pointer;
}
.text-heading{
   padding-bottom: 2px;
   border-bottom: 1px solid black
}

.non-editable{
    flex: 3 !important
}

.non-capitalized {
    text-transform: initial !important;
}
.scrollable{
    margin:20px 20px 20px 0px;
    border: 1px solid gray;
    padding:20px 20px 20px 10px;
}

@media only screen and (max-width:765px) {
    .non-editable{
        margin: 0 !important
    }
    .review-question{
        flex-direction: column
    }
    .review-question-answer{
        margin: 2px 0px 17px 0
    }
    .review-question-text{
        margin: 0px 0px 2px 0px;
        font-family: 'OpenSans'
    }
    .answer-bold{
        font-family: 'OpenSans-SemiBold';
    }
    .review-sub-header-area{
        margin: 0 0 20px 0
    }
    .review-header-area{
        margin: 22px 0 15px 0;
        padding: 30px 0 20px 0;
    }
  }
