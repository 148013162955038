.react-select-custom div div > span,
.react-select-custom-searchable div div > span {
  display: none;
}

.react-select-custom-searchable div div > svg {
  display: none;
}

.label_icon_star {
  color: "#0b808e";
  font-weight: 500;
}

.disable-page{
  pointer-events: none;
  opacity: 0.5;
}

.select-box-container {
  position: relative;
}

@media only screen and (min-width:480px) {
  .select-box-container {
    max-width:360px;
  }
}

.select-box-container .reflexive-question-loader {
  margin-top:-8px;
}

.select-box-loader-container {
  display:flex;
}

.dropdown-container {
  flex-grow:1;
}