.congratulations-questions-wrapper {
  min-height: 368px;

  .question-text {
    display: flex;
    p {
      margin: 0;
      margin-bottom: 5px;
    }
    .html-text {
      font-size: 14px;
      font-weight: normal;
    }
    .html-value {
      font-weight: bold;
    }
  }

  .html-value {
    color: #454545;
  }

  .html-paragraph {
    margin: 0;
    margin-bottom: 30px;
    
    .html-value {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .align-horizontal {
    display: flex;

    .html-paragraph {
      padding-left: 50px;
    }
  }
  .align-vertical {
    display: block;

    .html-title {
      font-size: 14px;
      font-weight: normal;
    }
  }
}
